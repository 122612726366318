import React from 'react';

// components
import Container from '../components/Container';

// images
import fail from '../images/fail.gif';

export default ({ data }) => {
  var title = 'Opps.. Page not found';
  var notFoundText =
    'Sorry for the inconvenience. You can use the navigation on the top of this page to find what you are looking for.';

  return (
    <Container>
      <section className="success ta-center">
        <div className="wrap-s">
          <h1>{title}</h1>
          <p>{notFoundText}</p>
          <div className="m-2">
            <img src={fail} alt="" />
          </div>
        </div>
      </section>
    </Container>
  );
};
